import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Icon from '@livingdesign/icons';
import {
  Button,
  Heading,
  TextField
} from '@walmart-web/livingdesign-components';

import styles from './styles.module.css';
import { logger } from '../../utils/logger';
import ApiPath from '../../constants/api';
import { isValidEmailId } from '../../utils/common';
import apiCall from '../../utils/api';
import API, { API_STATUS_CODE } from '../../constants/common';

type Props = {
  utmSource?: string;
};

const WaitlistFeaturedSection: React.FC<Props> = ({ utmSource = '' }) => {
  const [email, setEmail] = useState('');
  const [source, setSource] = useState(utmSource);
  const location = useLocation();
  const [validationMessage, setValidationMessage] = useState('');
  const [isPartOfCommunity, setIsPartOfCommunity] = useState(false);
  const { push } = useHistory();

  const isValidMedia = (value: string) => {
    const regex = /^[a-zA-Z0-9@._+-]+$/;
    if (!regex.test(value)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let paramsUtmSource = params.get('utm_source') || source;
    if (!isValidMedia(paramsUtmSource)) {
      paramsUtmSource = '';
    }
    setSource(paramsUtmSource);
    logger.analytics('waitlist_loaded', { utmsource: paramsUtmSource });
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
    setValidationMessage('');
    setIsPartOfCommunity(false);
  };

  const onJoin = () => {
    const joinEmail = email?.toLocaleLowerCase();
    if (!isValidEmailId(joinEmail)) {
      setValidationMessage(
        'Please enter a valid email that is associated with your Walmart.com account.'
      );
      return;
    }
    const payload = { emailId: joinEmail, mediaChannel: source };
    logger.clickStream('join_waitlist_btn', { email, source });
    apiCall(
      `${ApiPath.v1}${'waitlist/subscribe'}`,
      API.METHOD.POST,
      { ...payload },
      false
    )
      .then((response) => {
        if (response?.status === API_STATUS_CODE.CREATED) {
          setValidationMessage('');
          setEmail('');
          setIsPartOfCommunity(false);
          push('/waitlist?success=true');
        } else if (response?.status === API_STATUS_CODE.RATE_LIMIT) {
          logger.analytics('waitlist_rate_limit', payload);
          setValidationMessage(
            'We have reached the maximum number of requests for the day. Please come back tomorrow to join our waitlist!'
          );
        } else if (response?.error?.message?.includes('Validation Failed')) {
          logger.analytics('waitlist_validation_failed', payload);
          setValidationMessage(response?.error?.detail?.emailId);
        } else {
          if (
            response?.error?.message?.includes(
              'already a member of our community'
            )
          ) {
            logger.analytics('waitlist_exising_member', payload);
            setIsPartOfCommunity(true);
          }
          const message =
            response?.error?.message || response?.message || response?.error;
          logger.analytics('waitlist_response_err', { message });
          setValidationMessage(message);
        }
      })
      .catch((err) => {
        logger.error('waitlist_unknown_error', err);
        setValidationMessage('Something went wrong');
      });
  };

  return (
    <div className={styles.row}>
      <div
        className={`${styles.col} ${styles.sm12} ${styles.md6} ${styles.marginTop20} ${styles.infoContainer}`}
      >
        <Heading size="large">
          Not a member of our Customer Spark Community yet? Join our Waitlist!
        </Heading>
        <Heading
          size="small"
          UNSAFE_className={`${styles.marginTop30} ${styles.text}`}
        >
          The Walmart Customer Spark Community is an exclusive, invitation only
          community.
        </Heading>
        <Heading
          size="small"
          UNSAFE_className={`${styles.marginTop30} ${styles.text}`}
        >
          <span className={styles.fwb}>Join our waitlist </span>to increase your
          chances of receiving an invitation!
        </Heading>
        <div>
          <TextField
            className={`${styles.input} ${styles.marginTop10}`}
            disabled={false}
            placeholder="Enter your email"
            leadingIcon={<Icon.Email />}
            onChange={handleChange}
            readOnly={false}
            size="large"
            error={
              Boolean(validationMessage) && (
                <span data-testid="email-error">{validationMessage}</span>
              )
            }
            type="email"
            label={''}
            value={email}
            helperText="Please enter the email associated with your Walmart.com account."
            textFieldProps={{
              // @ts-ignore
              'data-testid': 'waitlist-email'
            }}
          />
          {isPartOfCommunity && (
            <p className={styles.error}>
              Please <Link to="login">log in.</Link>
            </p>
          )}
        </div>
        <Button
          size="large"
          className={`${styles.marginTop30} ${styles.button}`}
          disabled={!email}
          variant="primary"
          isFullWidth={false}
          onClick={onJoin}
          data-testid={'join-wailtlist-btn'}
        >
          Join waitlist
        </Button>
      </div>
      <div
        className={`${styles.col} ${styles.sm12} ${styles.md6} ${styles.justifyCenter}`}
      >
        <img
          src={'/assets/images/waitlist/banner.png'}
          width={'100%'}
          className={styles.bannerImage}
          alt="Waitlit"
        />
      </div>
    </div>
  );
};

export default WaitlistFeaturedSection;

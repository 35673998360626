import React, { useState } from 'react';
import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { LANDING_FAQ_CONTENT, LANDING_FAQ_LINKS } from '../../constants/common';
import styles from './styles';
import { FormattedMessage } from 'react-intl';

const LandingFaqContent = ({ classes }) => {
  const { contentBody, contentHead, faqLink, faqWrapper, note, seeMore } =
    classes;

  const [readMore, setReadMore] = useState(false);

  const handleSeeMore = () => {
    setReadMore(!readMore);
  };

  // converts provided input and replacements to array of text objects with link
  function convertStringToParsedLinkObjects(input, replacements) {
    const result = [];
    const regex = /{%([^%]+)%}/g;
    let match;
    let lastIndex = 0;

    while ((match = regex.exec(input)) !== null) {
      if (match.index > lastIndex)
        result.push({ text: input.substring(lastIndex, match.index) });
      let replacement = replacements[match[1]];
      if (replacement) result.push(replacement);
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < input.length)
      result.push({ text: input.substring(lastIndex) });
    return result;
  }

  const getFaqContent = () =>
    // Slicing faq content array to display only first 3 faqs when readMore is false, else displaying all
    LANDING_FAQ_CONTENT.slice(0, readMore ? LANDING_FAQ_CONTENT.length : 3).map(
      (faq, index) => (
        <>
          <Typography className={classNames(contentHead, 'smallHead')}>
            {faq.question}
          </Typography>
          <Typography className={contentBody}>
            {convertStringToParsedLinkObjects(
              faq.answer,
              LANDING_FAQ_LINKS
            ).map((parsedData) =>
              parsedData.link ? (
                <Link
                  href={parsedData.link}
                  className={faqLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  underline="always"
                >
                  {parsedData.text}
                </Link>
              ) : (
                parsedData.text
              )
            )}
          </Typography>
          {faq.note && (
            <Typography className={note}>
              <FormattedMessage
                id={`FAQ_NOTE_${index}`}
                defaultMessage={faq.note}
              />
            </Typography>
          )}
        </>
      )
    );

  const getSeeMoreLink = () => (
    <Link
      className={seeMore}
      onClick={handleSeeMore}
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
    >
      <FormattedMessage id="SEE" defaultMessage="See" />{' '}
      {!readMore ? (
        <FormattedMessage id="MORE" defaultMessage="more" />
      ) : (
        <FormattedMessage id="LESS" defaultMessage="less" />
      )}
    </Link>
  );

  return (
    <>
      <Grid item className={faqWrapper}>
        <Typography className={contentHead} style={{ textAlign: 'center' }}>
          <FormattedMessage
            id="FAQ"
            defaultMessage="Frequently Asked Questions"
          />
        </Typography>
        {getFaqContent()}
      </Grid>
      <Grid item style={{ textAlign: 'center' }}>
        {getSeeMoreLink()}
      </Grid>
    </>
  );
};

export default withStyles(styles)(LandingFaqContent);
